import Vue from "vue";
import VueRouter from "vue-router";
// import store from "@/store/index"

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: "/",
        name: "Home",
        meta: {
            keepAlive: false,
        },
        component: () => import("@/views/newindex"),
    },
    // {
    //     path: "/",
    //     name: "Home",
    //     meta: {
    //         keepAlive: false,
    //     },
    //     component: () => import("@/views/Home"),
    // },
    {
        path: '/story',
        name: 'story',
        component: () => import("@/views/story")
    },
    {
        path: '/onlinemsg',
        name: 'oniinemsg',
        meta: {
            keepAlive: false,
        },
        component: () => import("@/views/onlineMsg")
    },
    {
        path: '/wordmsg',
        name: "wordmsg",
        meta: {
            keepAlive: false,
        },
        component: () => import("@/views/wordMsg")
    }, {
        path: '/bridge',
        name: 'bridge',
        meta: {
            keepAlive: true,

        },
        component: () => import("@/views/bridge")
    }, {
        path: '/leaderinfo',
        name: 'leaderinfo',
        meta: {
            keepAlive: true,
        },
        component: () => import("@/views/leaderInfo")
    },
    {
        path: '/duty',
        name: 'duty',
        meta: {
            keepAlive: true,

        },
        component: () => import("@/views/duty")
    },
    {
        path: '/Leadingawish',
        name: 'Leadingawish',
        meta: {
            keepAlive: true,

        },
        component: () => import("@/views/Leadingawish")
    },
    {
        path: '/supervise',
        name: '/supervise',
        meta: {
            keepAlive: true,
        },
        component: () => import("@/views/supervise")
    }, {
        path: '/supervisedetail',
        name: 'supervisedetail',
        component: () => import("@/views/superviseDetail")
    },{
        path: '/supervisedetails',
        name: 'supervisedetails',
        component: () => import("@/views/superviseDetails")
    }, {
        path: '/notice',
        name: 'notice',
        meta: {
            keepAlive: true,

        },
        component: () => import("@/views/notice")
    }, {
        path: '/behalfFeedback',
        name: 'behalfFeedback',
        meta: {
            keepAlive: false,

        },
        component: () => import("@/views/behalfFeedback")
    }, {
        path: '/opinion',
        name: 'opinion',
        meta: {
            keepAlive: true
        },
        component: () => import("@/views/opinion")
    }, {
        path: '/textvideo',
        name: 'textvideo',
        meta: {
            keepAlive: false
        },
        component: () => import("@/views/textvideo")
    },
    {
        path: '/audionew',
        name: 'audionew',
        meta: {
            keepAlive: false
        },

        component: () => import("@/views/audionew")
    },
    {
        path: '/law',
        name: 'law',
        // meta: {
        //     keepAlive: true
        // },

        component: () => import("@/views/law")
    },{
        path: '/laws',
        name: 'laws',
        meta: {
            keepAlive: true
        },

        component: () => import("@/views/laws")
    },
    {
        path: '/peopleopinion',
        name: 'peopleopinion',
        meta: {
            keepAlive: false
        },

        component: () => import("@/views/peopleopinion")
    },
    {
        path: '/storytype',
        name: 'storytype',
        meta: {
            keepAlive: false
        },

        component: () => import("@/views/storytype")
    },
];

const router = new VueRouter({
    routes,
});


router.beforeEach((to, form, next) => {
    document.title = "广阳镇人大代表之家"
    next()

});

export default router;
