import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        openid: sessionStorage.getItem("openid") || null,
        avatar: sessionStorage.getItem("avatar") || "",
        Authorization: sessionStorage.getItem('Authorization') || '',
        // Authorization:'V4/0UE3Ouugxt39Y5q3RwJ+9tlC8Ypo+e+DIRkU0ydi7+24etkgUdnDaXkMwHQLpJ+cKtQGR2Q1GvTuiXB9p9Q==',
        nickname: sessionStorage.getItem("nickname") || "",
        LOADING: false,
        sign: "",
    },

    mutations: {
        loginSuccess(state, data) {
            state.openid = data.openid;
            state.avatar = data.avatar;
            state.nickname = data.nickname;
            state.Authorization = data.authorization;
            sessionStorage.setItem("openid", data.openid);
            sessionStorage.setItem("avatar", data.avatar);
            sessionStorage.setItem("nickname", data.nickname)
            sessionStorage.setItem("Authorization", data.authorization)
        },

        showLoading(state) {
            state.LOADING = true;
        },

        hideLoading(state) {
            state.LOADING = false;
        },

        saveSign(state, sign) {
            state.sign = sign;
            sessionStorage.setItem("sign", sign);
        },
    },

    actions: {},
    modules: {},
});
