import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
    Loading,
    Form,
    Field,
    Button,
    Popup,
    Image as VanImage,
    Col,
    Row,
    Cell,
    CellGroup,
    RadioGroup,
    Radio,
    Picker,
    DatetimePicker,
    TreeSelect,
    Tabbar,
    TabbarItem,
    Grid,
    GridItem,
    Icon,
    AddressList,
    AddressEdit,
    Area,
    Empty,
    List,
    Dialog,
    SwipeCell,
    Sidebar,
    SidebarItem,
    NoticeBar,
    Search,
    NavBar,
    Pagination,
    Uploader,
    Rate,
    Collapse,
    CollapseItem,
    Divider,
    Tag,
    Overlay
} from "vant";
import '@vant/touch-emulator';

Vue.use(Button);
Vue.use(Popup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Loading);
Vue.use(VanImage);
Vue.use(Col);
Vue.use(Row);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(TreeSelect);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Icon);
Vue.use(AddressList);
Vue.use(AddressEdit);
Vue.use(Area);
Vue.use(Empty);
Vue.use(List);
Vue.use(Dialog);
Vue.use(SwipeCell);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(NoticeBar);
Vue.use(Search);
Vue.use(NavBar);
Vue.use(Pagination);
Vue.use(Uploader);
Vue.use(Rate);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Divider);
Vue.use(Tag);
Vue.use(Overlay)


Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");