<template>
  <div id="app">
    <Loading v-show="LOADING"></Loading>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>


<script>
import Loading from "@/components/Loading";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["LOADING"]),
  },
  components: { Loading },
};
</script>


<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
@import url(./assets/css/public.less);
@import url(./assets/css/equipment.less);
</style>
